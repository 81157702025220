<template>
    <div>
        <el-dialog title="查看点位" :visible.sync="dialogVisible">
            <div class="a-flex-cfsfs">
                <span class="a-fs-14">{{positionObject.name}}</span>
                <span class="a-c-second a-fs-12">{{positionObject.address}}</span>
            </div>
            <div class="mapContent" id="checkMap" ref="checkMap"></div>
            <div slot="footer" class="dialog-footer" style="text-align:center">
                <!-- <el-button @click="dialogFormVisible = false">取 消</el-button> -->
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>
<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        positionObject: {
            type: Object,

        }
    },
    data() {
        return {
            dialogVisible: false,
            mapContent: null,
            marker: null,
            center:null,
            timer:null
        }
    },
    watch:{
        'positionObject':{
            handler(val,old){
          

            },
            deep:true
        },
        'visible':{        
            handler(val,old){
                  this.dialogVisible=this.visible               
            },
            deep:true
        },
        dialogVisible:{
            handler(val){
                this.$emit("update:visible", val)
                if(!val){                  
                this.mapContent.destroy();
                 this.mapContent=null
                    this.marker=null
                }else{
                    this.timer=  setTimeout(()=>{
                        this.initMap()
                    },100)
                }
            }
        }

    },

    mounted() {
    },
   

    methods: {
        initMap() {
          if(this.positionObject.lat && this.positionObject.lng){
            this.center =new TMap.LatLng(this.positionObject.lat, this.positionObject.lng)
          }else{
this.center=new TMap.LatLng(39.98210863924864, 116.31310899739151)
          }
            this.mapContent = new TMap.Map('checkMap', {
                zoom: 15,//设置地图缩放
                center: this.center,
                pitch: 45,//俯仰度
                rotation: 0,//旋转角度
            })
            if (this.positionObject.lat && this.positionObject.lng) {
              this.markerMap()}
        },
        markerMap(){
              this.marker = new TMap.MultiMarker({
                     id: "marker-layer", //图层id
                    'map': this.mapContent,
                    'styles': {
                        // 点标记样式
                        'marker': new TMap.MarkerStyle({
                            'width': 24, // 样式宽
                            'height': 34, // 样式高
                            'anchor': { x: 10, y: 30 }, // 描点位置
                        }),
                    },
                    geometries: [
                        // 点标记数据数组
                        {
                            // 标记位置(纬度，经度，高度)
                            'position': this.center,
                            'id': 'marker',
                            "styleId": "marker",
                        },
                    ],
                });
        }
    }
}
</script>
<style lang="scss" scoped>
.mapContent {
    width: 100%;
    height: 341px;
    margin-top: 16px;
}

/deep/ .el-dialog__body {
    padding-top: 0;

}
</style>