<template>
		<el-card class="a-p-16 a-fs-14 a-c-333" style="width: 640px; padding: 16px !important;">

			<div class="a-w-100 a-flex-rsbc">
				<div class="a-flex-rcc">
					<span class="a-fs-16 a-fw-b">{{price.name}}</span>
					<div class="s-tag-blue a-ml-08">{{price.status==0?'生效中':'已停用'}}</div>
					<div class="s-tag-orange a-ml-08"><span>{{ price.type | initDic(chargingPlanTypeDic) }}</span></div>
				</div>
				<div class="a-flex-rcc" v-if="!readOnly">
					<div class="a-flex-rcc a-cursor-p" @click="handlerEditPrice" v-if="$_has(10)">
						<img src="../../../assets/icon/option-edit.png" class="a-wh-16" />
						<span class="a-fs-14 a-c-blue a-ml-08">编辑</span>
					</div>
					<div class="a-flex-rcc a-cursor-p a-ml-16" @click="handlerChoosePrice" v-if="$_has(9)">
						<img src="../../../assets/icon/option-edit.png" class="a-wh-16" />
						<span class="a-fs-14 a-c-blue a-ml-08">选择</span>
					</div>
				</div>
				
			</div>
			<div class="a-mt-16">
				<span>{{price.description}}</span>
			</div>
            <div v-if="price.type==1 || price.type==6">
                <el-row :gutter="20" class="a-mt-16">
                    <el-col :span="14">
                        <div class="a-flex-rfsc">
                            <span class="a-c-999">起步费用</span>
                            <span
                                class="a-ml-16">{{util.numFormat(price.baseAmount)}}元/{{price.baseMinutes%60==0?(price.baseMinutes/60+'小时'):(price.baseMinutes+'分钟')}}</span>
                        </div>
                    </el-col>
                    <el-col :span="10">
                        <div class="a-flex-rfsc">
                            <span class="a-c-999">犹豫时长</span>
                            <span class="a-ml-16">{{price.hesitationMinutes}}分钟</span>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="20" class="a-mt-8">
                    <el-col :span="14">
                        <div class="a-flex-rfsc ">
                            <span class="a-c-999">加时费用</span>
                            <span
                                class="a-ml-16">{{util.numFormat(price.stepAmount)}}元/{{price.stepMinutes%60==0?(price.stepMinutes/60+'小时'):(price.stepMinutes+'分钟')}}</span>
                        </div>
                    </el-col>
                    <el-col :span="10">
                        <div class="a-flex-rfsc">
                            <span class="a-c-999">最长时长</span>
                            <span class="a-ml-16">{{price.maxMinutes%60==0?(price.maxMinutes/60+'小时'):(price.maxMinutes+'分钟')}}</span>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="20" class="a-mt-8" v-if="price.type==1">
                    <el-col :span="14">
                        <div class="a-flex-rfsc ">
                            <span class="a-c-999">是否自选功率</span>
                            <span class="a-ml-16">{{ price.selfPower ? '是' : '否' }}</span>
                        </div>
                    </el-col>
                </el-row>
                <div class="a-mt-8">
                    <span class="a-c-orange">共有{{stationSize}}个站点正在使用该定价</span>
                </div>
                <div v-if="power.length>0" class="a-mt-24">
                    <el-table :data="power" :highlight-current-row="true">
                        <el-table-column label="功率" align="left">
                            <template slot-scope="{ row }">
                                <span>{{row.minPower}} - {{row.maxPower}}瓦</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="address" label="起步费用" align="left">
                            <template slot-scope="{ row }">
                                <span>{{ util.numFormat(row.baseAmount) }}元/{{ row.baseMinutes }}分钟</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="statusText" label="加时费用" align="left">
                            <template slot-scope="{ row }">
                                <span>{{ util.numFormat(row.stepAmount) }}元/{{ row.stepMinutes }}分钟</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <el-row :gutter="14" class="a-mt-8" v-if="price.needManagermentFee">
                    <el-col :span="14">
                        <div class="a-flex-rfsc">
                            <span class="a-c-999">管理费用</span>
                            <span
                                class="a-ml-16">{{util.numFormat(price.managermentFee)}}元/{{price.managermentMinutes%60==0?(price.managermentMinutes/60+'小时'):(price.managermentMinutes+'分钟')}}</span>
                        </div>
                    </el-col>
                </el-row>
            </div>
			<div v-if="price.type==2">
                <el-row :gutter="20" class="a-mt-16">
                    <el-col :span="14">
                        <div class="a-flex-rfsc">
                            <span class="a-c-999">电费</span>
                            <span
                                class="a-ml-16">{{util.numFormatExcept10To5(price.baseAmount)}}元/kwh</span>
                        </div>
                    </el-col>
                    <el-col :span="10">
                        <div class="a-flex-rfsc">
                            <span class="a-c-999">犹豫时长</span>
                            <span class="a-ml-16">{{price.hesitationMinutes}}分钟</span>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="20" class="a-mt-8">
                    <el-col :span="14">
                        <div class="a-flex-rfsc ">
                            <span class="a-c-999">电费服务费</span>
                            <span
                                class="a-ml-16">{{util.numFormat(price.chargeBaseFee)}}元/{{ price.chargeBaseTime }}分钟</span>
                        </div>
                    </el-col>
                    <el-col :span="14" v-if="price.serviceBaseFee">
                        <div class="a-flex-rfsc ">
                            <span class="a-c-999">占位服务费</span>
                            <span
                                class="a-ml-16">{{util.numFormat(price.serviceBaseFee)}}元/{{ price.serviceBaseTime }}分钟</span>
                        </div>
                    </el-col>
                </el-row>
                <div class="a-mt-8">
                    <span class="a-c-orange">共有{{stationSize}}个站点正在使用该定价</span>
                </div>
                <div v-if="ruleList.length>0 && price.serviceBaseFee" class="a-mt-24">
                    <el-table :data="ruleList" :highlight-current-row="true">
                        <el-table-column label="时间段">
                            <template slot-scope="{ row }">
                                <span>{{row.startTime}} - {{row.endTime}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="费用">
                            <template slot-scope="{ row }">
                                <span>{{ row.serviceFee }}元/{{ row.serviceMinutes }}分钟</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="生效条件">
                            <template slot-scope="{ row }">
                                <span>{{ row.feeRuleType | initDic(feeRuleTypeDic) }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <el-row :gutter="14" class="a-mt-8" v-if="price.needManagermentFee">
                    <el-col :span="14">
                        <div class="a-flex-rfsc">
                            <span class="a-c-999">管理费用</span>
                            <span
                                class="a-ml-16">{{util.numFormat(price.managermentFee)}}元/{{price.managermentMinutes%60==0?(price.managermentMinutes/60+'小时'):(price.managermentMinutes+'分钟')}}</span>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div v-if="price.type==3 || price.type==4 || price.type==8">
                <el-row :gutter="20" class="a-mt-16">
                    <el-col :span="14">
                        <div class="a-flex-rfsc">
                            <span class="a-c-999">起步费用</span>
                            <span class="a-ml-16">{{util.numFormat(price.baseAmount)}}元/{{price.baseMinutes%60==0?(price.baseMinutes/60+'小时'):(price.baseMinutes+'分钟')}}</span>
                        </div>
                    </el-col>
                    <el-col :span="10">
                        <div class="a-flex-rfsc">
                            <span class="a-c-999">犹豫时长</span>
                            <span class="a-ml-16">{{price.hesitationMinutes}}分钟</span>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="14" class="a-mt-8">
                    <el-col :span="14">
                        <div class="a-flex-rfsc ">
                            <span class="a-c-999">加时费用</span>
                            <span class="a-ml-16">{{util.numFormat(price.stepAmount)}}元/{{price.stepMinutes%60==0?(price.stepMinutes/60+'小时'):(price.stepMinutes+'分钟')}}</span>
                        </div>
                    </el-col>
                    <el-col :span="10">
                        <div class="a-flex-rfsc ">
                            <span class="a-c-999">服务费</span>
                            <span
                                class="a-ml-16">{{util.numFormat(price.serviceBaseFee)}}元/{{ price.serviceBaseTime }}分钟</span>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="14" class="a-mt-8" v-if="price.needManagermentFee">
                    <el-col :span="14">
                        <div class="a-flex-rfsc">
                            <span class="a-c-999">管理费用</span>
                            <span
                                class="a-ml-16">{{util.numFormat(price.managermentFee)}}元/{{price.managermentMinutes%60==0?(price.managermentMinutes/60+'小时'):(price.managermentMinutes+'分钟')}}</span>
                        </div>
                    </el-col>
                    <el-col :span="14" v-if="price.type == 4">
                        <div class="a-flex-rfsc ">
                            <span class="a-c-999">是否自选功率</span>
                            <span class="a-ml-16">{{ price.selfPower ? '是' : '否' }}</span>
                        </div>
                    </el-col>
                </el-row>
 
                <div class="a-mt-8">
                    <span class="a-c-orange">共有{{stationSize}}个站点正在使用该定价</span>
                </div>
                <div v-if="power.length>0" class="a-mt-24">
                    <el-table :data="power" :highlight-current-row="true">
                        <el-table-column label="功率" align="left">
                            <template slot-scope="{ row }">
                                <span>{{row.minPower}} - {{row.maxPower}}瓦</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="address" label="起步费用" align="left">
                            <template slot-scope="{ row }">
                                <span>{{ util.numFormat(row.baseAmount) }}元/{{ row.baseMinutes }}分钟</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="statusText" label="加时费用" align="left">
                            <template slot-scope="{ row }">
                                <span>{{ util.numFormat(row.stepAmount) }}元/{{ row.stepMinutes }}分钟</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div v-if="ruleList.length>0" class="a-mt-24">
                    <el-table :data="ruleList" :highlight-current-row="true">
                        <el-table-column label="时间段">
                            <template slot-scope="{ row }">
                                <span>{{row.startTime}} - {{row.endTime}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="费用">
                            <template slot-scope="{ row }">
                                <span>{{ row.serviceFee }}元/{{ row.serviceMinutes }}分钟</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="生效条件">
                            <template slot-scope="{ row }">
                                <span>{{ row.feeRuleType | initDic(feeRuleTypeDic) }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div v-if="price.type==5 || price.type==10 || price.type==11">
                <el-row :gutter="20" class="a-mt-16">
                    <el-col :span="14">
                        <div class="a-flex-rfsc">
                            <span class="a-c-999">电费</span>
                            <span
                                class="a-ml-16">{{util.numFormatExcept10To5(price.baseAmount)}}元/kwh</span>
                        </div>
                    </el-col>
                    <el-col :span="10">
                        <div class="a-flex-rfsc">
                            <span class="a-c-999">犹豫时长</span>
                            <span class="a-ml-16">{{price.hesitationMinutes}}分钟</span>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="20" class="a-mt-8">
                    <el-col :span="14">
                        <div class="a-flex-rfsc ">
                            <span class="a-c-999">电费服务费</span>
                            <span
                                class="a-ml-16">{{util.numFormatExcept10To5(price.chargeBaseFee)}}元/kwh</span>
                        </div>
                    </el-col>
                    <el-col :span="14">
                        <div class="a-flex-rfsc">
                            <span class="a-c-999">管理费用</span>
                            <span
                                class="a-ml-16">{{util.numFormat(price.managermentFee)}}元/{{price.managermentMinutes%60==0?(price.managermentMinutes/60+'小时'):(price.managermentMinutes+'分钟')}}</span>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="20" class="a-mt-8">
                    <el-col :span="14" v-if="price.serviceBaseFee">
                        <div class="a-flex-rfsc ">
                            <span class="a-c-999">占位服务费</span>
                            <span
                                class="a-ml-16">{{util.numFormat(price.serviceBaseFee)}}元/{{ price.serviceBaseTime }}分钟</span>
                        </div>
                    </el-col>
                </el-row>
                <div class="a-mt-8">
                    <span class="a-c-orange">共有{{stationSize}}个站点正在使用该定价</span>
                </div>
                <div v-if="ruleList.length>0 && price.serviceBaseFee" class="a-mt-24">
                    <el-table :data="ruleList" :highlight-current-row="true">
                        <el-table-column label="时间段">
                            <template slot-scope="{ row }">
                                <span>{{row.startTime}} - {{row.endTime}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="费用">
                            <template slot-scope="{ row }">
                                <span>{{ row.serviceFee }}元/{{ row.serviceMinutes }}分钟</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="生效条件">
                            <template slot-scope="{ row }">
                                <span>{{ row.feeRuleType | initDic(feeRuleTypeDic) }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div v-if="price.type==7 || price.type==12">
                <el-row :gutter="20" class="a-mt-16">
                    <el-col :span="14">
                        <div class="a-flex-rfsc">
                            <span class="a-c-999">电费</span>
                            <span
                                class="a-ml-16">{{util.numFormatExcept10To5(price.baseAmount)}}元/kwh</span>
                        </div>
                    </el-col>
                    <el-col :span="10">
                        <div class="a-flex-rfsc">
                            <span class="a-c-999">犹豫时长</span>
                            <span class="a-ml-16">{{price.hesitationMinutes}}分钟</span>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="20" class="a-mt-8">
                    <el-col :span="14">
                        <div class="a-flex-rfsc">
                            <span class="a-c-999">管理费用</span>
                            <span
                                class="a-ml-16">{{util.numFormat(price.managermentFee)}}元/{{price.managermentMinutes%60==0?(price.managermentMinutes/60+'小时'):(price.managermentMinutes+'分钟')}}</span>
                        </div>
                    </el-col>
                    <el-col :span="10">
                        <div class="a-flex-rfsc ">
                            <span class="a-c-999">是否自选功率</span>
                            <span class="a-ml-16">{{ price.selfPower ? '是' : '否' }}</span>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="20" class="a-mt-8">
                    <el-col :span="14" v-if="price.serviceBaseFee">
                        <div class="a-flex-rfsc ">
                            <span class="a-c-999">占位服务费</span>
                            <span
                                class="a-ml-16">{{util.numFormat(price.serviceBaseFee)}}元/{{ price.serviceBaseTime }}分钟</span>
                        </div>
                    </el-col>
                </el-row>
                <div class="a-mt-8">
                    <span class="a-c-orange">共有{{stationSize}}个站点正在使用该定价</span>
                </div>
                <div v-if="ruleList.length>0 && price.serviceBaseFee" class="a-mt-24">
                    <el-table :data="ruleList" :highlight-current-row="true">
                        <el-table-column label="时间段">
                            <template slot-scope="{ row }">
                                <span>{{row.startTime}} - {{row.endTime}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="费用">
                            <template slot-scope="{ row }">
                                <span>{{ row.serviceFee }}元/{{ row.serviceMinutes }}分钟</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="生效条件">
                            <template slot-scope="{ row }">
                                <span>{{ row.feeRuleType | initDic(feeRuleTypeDic) }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <span class="a-mt-24 a-fw-700 block">功率服务费：</span>
                <div v-if="powerServiceDetail.length>0" >
                    <el-table :data="powerServiceDetail" :highlight-current-row="true">
                        <el-table-column label="功率">
                            <template slot-scope="{ row }">
                                <span>{{row.minPower}} - {{row.maxPower}}瓦</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="address" label="服务费">
                            <template slot-scope="{ row }">
                                <span>{{ row.serviceBaseFee }}元/{{ row.serviceBaseTime }}分钟</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div v-if="price.type==9">
                <el-row :gutter="20" class="a-mt-16">
                    <el-col :span="14">
                        <div class="a-flex-rfsc">
                            <span class="a-c-999">电费</span>
                            <span
                                class="a-ml-16">{{util.numFormatExcept10To5(price.baseAmount)}}元/kwh</span>
                        </div>
                    </el-col>
                    <el-col :span="10">
                        <div class="a-flex-rfsc">
                            <span class="a-c-999">犹豫时长</span>
                            <span class="a-ml-16">{{price.hesitationMinutes}}分钟</span>
                        </div>
                    </el-col>
                </el-row>
                <div class="a-mt-8">
                    <span class="a-c-orange">共有{{stationSize}}个站点正在使用该定价</span>
                </div>
            </div>
		</el-card>
	
</template>
<script>
	import util from '../../../utils/util.js'
	import LePriceChoose from '../choose/le-price-choose.vue'
	export default {
		components:{
			'le-price-choose':LePriceChoose
		},
		props: {
			priceId: {
				required: false,
			},
			readOnly:{
				type:Boolean,
				default:false
			}
		},
		data() {
			return {
				util: util,
				price: {

				},
				power: [],
                ruleList: [],
                powerServiceDetail: [],
				stationSize: 0,
				feeRuleTypeDic: [],
                chargingPlanTypeDic: []
			};
		},
		watch: {
			priceId: {
				handler(val) {
					if (val) {
						this.getPlanDetail();
						this.getPlanPower();
                        this.getPlanSpecial()
						this.getPlanUserStationSize();
                        this.getPlanServicePower()
					}
				},
				immediate: true
			},
			
		},
        created () {
            this.$getDic('feeRuleType').then(res=>{ this.feeRuleTypeDic = res; })
            this.$getDic('chargingPlanType','select').then(res=>{ this.chargingPlanTypeDic = res; })
        },
		methods: {
			/**
			 * 获取定价详情
			 */
			getPlanDetail() {
				this.$Axios._get({
					url: this.$Config.apiUrl.getPlanDetail,
					params: {
						chargingPlanId: this.priceId
					}
				}).then(({
					data
				}) => {
					this.price = data;
				})
			},
			/**
			 * 获取功率计费详情
			 */
			getPlanPower() {
				this.$Axios._get({
					url: this.$Config.apiUrl.getPlanPower,
					params: {
						chargingPlanId: this.priceId
					}
				}).then(({
					data
				}) => {
                    if(data && data.length) {
                        this.power = data.map(item=>{
                            return {
                                ...item,
                                baseAmount: item.price,
                                baseMinutes: item.minute,
                                stepAmount: item.stepPrice,
                                stepMinutes: item.stepMinute
                            }
                        });
                    }
					
				})
			},
            /**
			 * 获取服务费计费特殊规则
			 */
            getPlanSpecial () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.getPlanRule,
                    params: {
                        chargingPlanId: this.priceId
                    }
                }).then(({
                    data
                }) => {
                    let datas = data
                    datas = datas.map(item=>{
                        return {
                            ...item,
                            serviceFee: item.serviceFee?Number((item.serviceFee/100).toFixed(2)):'0',
                            feeRuleType: JSON.stringify(item.feeRuleType)
                        }
                    })
                    this.ruleList = datas
                
                })
            },
            getPlanServicePower () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.getPowerServiceDetail + '?chargingPlanId=' + this.priceId,
                }).then(({
                    data
                }) => {
                    if (data && data.length > 0) {
                        this.powerServiceDetail = data.map(item => {
                            return {
                                ...item,
                                serviceBaseFee: (item.price/100).toFixed(2),
                                serviceBaseTime: item.minute,
                            }
                        });
                        console.log(this.powerServiceDetail,1111);
                    }
                })
            },
			/**
			 * 获取定价相关的站点数
			 */
			getPlanUserStationSize() {
				this.$Axios._get({
					url: this.$Config.apiUrl.getPlanUserStationSize,
					params: {
						chargingPlanId: this.priceId
					}
				}).then(({
					data
				}) => {
					this.stationSize = data;
				})
			},

			/**
			 * 编辑定价
			 */
			handlerEditPrice() {
				if(this.priceId){
					this.$router.push({
						path:'/price/price-edit',
						query:{
							'priceId': this.priceId,
                            type: this.price.type
						}
					})
				}
			},

			handlerChoosePrice(){
				this.$emit('choosePrice')
			}
			
		},
	};
</script>

<style scoped>
	
</style>
